import { Formik } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { UpdatePostingFormValidation } from './UpdatePostingFormValidation';
import { useContext, useState } from 'react';
import { HelpTypes } from '../../types/chessLingo';
import { useLocation, useNavigate } from 'react-router-dom';
import { UpdatePostingFormValues } from '../../types/formTypes';
import { updatePosting } from '../../api/updatePosting';
import { StatusAlertContext } from '../../components';

export const updatePostingFormFields = {
  helpType: {
    label: 'What can we help you with?',
    placeholder: 'Choose an option...',
    name: 'helpType',
    controlId: 'createPostingHelpType',
  },
  gameLink: {
    label: 'Enter online game link',
    placeholder: 'i.e. www.chesswala.com',
    type: 'text',
    name: 'gameLink',
    controlId: 'createPostingGameLink',
  },
  description: {
    label: 'What do you want to get out of your lesson?',
    placeholder:
      'i.e. I want to gain a better understanding of how to play the Sicilian. I want to add a little spice to my repertoire!',
    type: 'text',
    name: 'description',
    controlId: 'createPostingDescription',
  },
  opening: {
    label: 'Enter desired opening',
    placeholder: 'i.e. Sicilian Defense for Black',
    type: 'text',
    name: 'opening',
    controlId: 'createPostingOpening',
  },
  promoCode: {
    label: 'Streamer Code / Promo Code (Optional)',
    placeholder: 'Type here...',
    type: 'text',
    name: 'promoCode',
    controlId: 'createPostingPromoCode',
  },
};

const UpdatePostingForm = () => {
  const { setStatusAlert } = useContext(StatusAlertContext);

  const [loadingSubmitForm, setLoadingSubmitForm] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { postId, helpType, description, opening, gameLink, promoCode } =
    location.state;

  const UpdatePostingFormInitialValues: UpdatePostingFormValues = {
    helpType: helpType,
    description: description,
    opening: opening,
    gameLink: gameLink,
    promoCode: promoCode,
  };

  const handleSubmit = async (
    values: typeof UpdatePostingFormInitialValues
  ) => {
    setLoadingSubmitForm(true);
    try {
      await updatePosting(values, postId);
      navigate('/my-postings');
      setStatusAlert('Success! Your posting has been updated.');
    } catch {
      setStatusAlert('Error: Please Try Again');
      window.scrollTo(0, 0);
    }
    setLoadingSubmitForm(false);
  };

  return (
    <Formik
      validationSchema={UpdatePostingFormValidation}
      onSubmit={handleSubmit}
      initialValues={UpdatePostingFormInitialValues}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group
              className='mb-3 mt-4'
              controlId={updatePostingFormFields.helpType.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {updatePostingFormFields.helpType.label}
              </Form.Label>
              <Form.Select
                name={updatePostingFormFields.helpType.name}
                value={values.helpType}
                onChange={handleChange}
                isValid={touched.helpType && !errors.helpType}
                isInvalid={touched.helpType && !!errors.helpType}
                placeholder={updatePostingFormFields.helpType.placeholder}
              >
                <option>{HelpTypes.Analysis}</option>
                <option>{HelpTypes.Opening}</option>
              </Form.Select>
              <Form.Control.Feedback type='invalid'>
                {errors.helpType}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={
                values.helpType === HelpTypes.Analysis
                  ? 'mb-3'
                  : 'create-posting-form-invisible'
              }
              controlId={updatePostingFormFields.gameLink.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {updatePostingFormFields.gameLink.label}
              </Form.Label>
              <Form.Control
                type={updatePostingFormFields.gameLink.type}
                name={updatePostingFormFields.gameLink.name}
                value={values.gameLink}
                onChange={handleChange}
                isValid={touched.gameLink && !errors.gameLink}
                isInvalid={touched.gameLink && !!errors.gameLink}
                placeholder={updatePostingFormFields.gameLink.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.gameLink}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={
                values.helpType === HelpTypes.Opening
                  ? 'mb-3'
                  : 'create-posting-form-invisible'
              }
              controlId={updatePostingFormFields.opening.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {updatePostingFormFields.opening.label}
              </Form.Label>
              <Form.Control
                type={updatePostingFormFields.opening.type}
                name={updatePostingFormFields.opening.name}
                value={values.opening}
                onChange={handleChange}
                isValid={touched.opening && !errors.opening}
                isInvalid={touched.opening && !!errors.opening}
                placeholder={updatePostingFormFields.opening.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.opening}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId={updatePostingFormFields.description.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {updatePostingFormFields.description.label}
              </Form.Label>
              <Form.Control
                type={updatePostingFormFields.description.type}
                name={updatePostingFormFields.description.name}
                value={values.description}
                onChange={handleChange}
                isValid={touched.description && !errors.description}
                isInvalid={touched.description && !!errors.description}
                placeholder={updatePostingFormFields.description.placeholder}
                as='textarea'
                rows={4}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId={updatePostingFormFields.promoCode.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {updatePostingFormFields.promoCode.label}
              </Form.Label>
              <Form.Control
                type={updatePostingFormFields.promoCode.type}
                name={updatePostingFormFields.promoCode.name}
                value={values.promoCode}
                onChange={handleChange}
                isValid={touched.promoCode && !errors.promoCode}
                isInvalid={touched.promoCode && !!errors.promoCode}
                placeholder={updatePostingFormFields.promoCode.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.promoCode}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className='mb-3 btn-chesswala create-posting-form-button'
              type='submit'
              disabled={loadingSubmitForm}
            >
              {'Update My Posting '}
              {loadingSubmitForm && <Spinner animation='border' size='sm' />}
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default UpdatePostingForm;
