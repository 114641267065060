import { Card, Col, Row } from 'react-bootstrap';
import { CoachCardProps } from '../../types/propTypes';

const CoachCard = ({
  img,
  title,
  name,
  peakRating,
  specialties,
}: CoachCardProps) => {
  return (
    <Card className='mx-auto coach-card-card' text='dark'>
      <Card.Img
        className='mx-auto mt-3 coach-card-img'
        variant='top'
        src={img}
        alt='chesswala coach'
      ></Card.Img>

      <Card.Body>
        <Row xs='auto'>
          <Col className='pe-2'>
            <Card.Title className='fw-bold'>{title}</Card.Title>
          </Col>
          <Col className='px-0'>
            <Card.Title>{name}</Card.Title>
          </Col>
        </Row>
        <Card.Text>{'Specialties: ' + specialties.join(', ')}</Card.Text>
      </Card.Body>
      <Card.Footer className='coach-card-footer'>
        <small className='coach-card-specialties'>
          {'Peak Rating: ' + peakRating.toString()}
        </small>
      </Card.Footer>
    </Card>
  );
};

export default CoachCard;
