import { Formik, FormikHelpers } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { ContactUsFormValidation } from './ContactUsFormValidation';
import { contactUs } from '../../api/contactUs';
import { ContactUsFormValues } from '../../types/formTypes';
import { StatusAlertContext } from '../../components';
import { useContext, useState } from 'react';

export const contactUsFormFields = {
  message: {
    placeholder: 'Type here...',
    type: 'text',
    name: 'message',
    controlId: 'contactUsMessage',
  },
};
const ContactUsFormInitialValues: ContactUsFormValues = {
  message: '',
};

const ContactUsForm = () => {
  const { setStatusAlert } = useContext(StatusAlertContext);

  const [loadingSubmitForm, setLoadingSubmitForm] = useState<boolean>(false);

  const handleSubmit = async (
    values: ContactUsFormValues,
    { resetForm }: FormikHelpers<ContactUsFormValues>
  ) => {
    setLoadingSubmitForm(true);
    try {
      await contactUs(values);
      resetForm();
      setStatusAlert('Success! We have received your message.');
    } catch (err) {
      setStatusAlert('Error: Please Try Again');
    }
    setLoadingSubmitForm(false);
    window.scrollTo(0, 0);
  };

  return (
    <Formik
      validationSchema={ContactUsFormValidation}
      onSubmit={handleSubmit}
      initialValues={ContactUsFormInitialValues}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group
              className='mb-3'
              controlId={contactUsFormFields.message.controlId}
            >
              <Form.Control
                type={contactUsFormFields.message.type}
                name={contactUsFormFields.message.name}
                as='textarea'
                rows={4}
                value={values.message}
                onChange={handleChange}
                isValid={touched.message && !errors.message}
                isInvalid={touched.message && !!errors.message}
                placeholder={contactUsFormFields.message.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className='mb-3 btn-chesswala contact-us-form-button'
              type='submit'
              disabled={loadingSubmitForm}
            >
              {'SEND '}
              {loadingSubmitForm && <Spinner animation='border' size='sm' />}
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ContactUsForm;
