import { API } from 'aws-amplify';
import { PostingData } from '../types/dataTypes';

export const getPostingsHomePage = async (): Promise<PostingData[]> => {
  const response = await API.get(
    'chesswalaAPI',
    '/api/get-postings-home-page',
    {}
  );
  return response;
};
