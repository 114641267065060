import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <Container fluid className='border-top mt-auto ps-4'>
      <img
        alt='chesswala logo'
        src='chesswalaLogoCropped.png'
        className='d-inline-block align-top my-4 footer-logo'
      />
    </Container>
  );
};

export default Footer;
