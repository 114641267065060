import { Button, Col, Container, Ratio, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const CreatePostingHome = () => {
  return (
    <Container fluid className='ps-4'>
      <Row>
        <Col lg={6} className='mb-4 px-5'>
          <p className='create-posting-home-header'>
            Create a posting today and receive a screen-recorded video made by
            one of our master-level coaches!
          </p>
          <p>
            Here, you can see NM Alec Andersen in the middle of a rigorous
            analysis for a Chesswala learner. Our coaches all have previous
            private coaching experience, and are guaranteed to help you achieve
            the results you want.
          </p>
          <LinkContainer
            to='/create-posting'
            className='create-posting-home-button'
          >
            <Button size='lg' className='btn-chesswala mx-auto mt-3'>
              Create a Posting
            </Button>
          </LinkContainer>
        </Col>
        <Col className='px-5'>
          <Ratio aspectRatio='16x9'>
            <iframe
              title='ChesswalaVideo2'
              src='https://www.youtube.com/embed/lLWRIgt1zHQ'
            />
          </Ratio>
        </Col>
      </Row>
    </Container>
  );
};

export default CreatePostingHome;
