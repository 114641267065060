import { Formik, FormikHelpers } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { BidFormValidation } from './BidFormValidation';
import { bid } from '../../api/bid';
import { BidFormProps } from '../../types/propTypes';
import { BidFormValues } from '../../types/formTypes';
import { useContext, useState } from 'react';
import { StatusAlertContext } from '../../components';

export const BidFormFields = {
  price: {
    placeholder: 'How much will you charge',
    type: 'number',
    name: 'price',
    controlId: 'bidPrice',
  },
  message: {
    placeholder: 'Type here...',
    type: 'text',
    name: 'message',
    controlId: 'bidMessage',
  },
};

export const BidFormInitialValues: BidFormValues = {
  price: 0,
  message: '',
};

const BidForm = ({
  disableBid,
  hideBidModal,
  posterId,
  postId,
  posterName,
}: BidFormProps) => {
  const { setStatusAlert } = useContext(StatusAlertContext);

  const [loadingSubmitForm, setLoadingSubmitForm] = useState<boolean>(false);

  const handleSubmit = async (
    values: BidFormValues,
    { resetForm }: FormikHelpers<BidFormValues>
  ) => {
    setLoadingSubmitForm(true);
    try {
      await bid(posterId, postId, values.price, values.message);
      resetForm();
      disableBid();
      hideBidModal();
      setStatusAlert('Success! Your bid has been sent to ' + posterName);
    } catch (err) {
      setStatusAlert('Error: Please Try Again');
    }
    setLoadingSubmitForm(false);
    window.scrollTo(0, 0);
  };

  return (
    <Formik
      validationSchema={BidFormValidation}
      onSubmit={handleSubmit}
      initialValues={BidFormInitialValues}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group
              className='mb-3 mt-3'
              controlId={BidFormFields.price.controlId}
            >
              <Form.Label className='bid-form-label'>
                Enter your bid price
              </Form.Label>
              <Form.Control
                type={BidFormFields.price.type}
                name={BidFormFields.price.name}
                value={values.price}
                onChange={handleChange}
                isValid={touched.price && !errors.price}
                isInvalid={touched.price && !!errors.price}
                placeholder={BidFormFields.price.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.price}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId={BidFormFields.message.controlId}
            >
              <Form.Label className='bid-form-label'>
                Write a message
              </Form.Label>
              <Form.Control
                type={BidFormFields.message.type}
                name={BidFormFields.message.name}
                as='textarea'
                rows={4}
                value={values.message}
                onChange={handleChange}
                isValid={touched.message && !errors.message}
                isInvalid={touched.message && !!errors.message}
                placeholder={BidFormFields.message.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className='mb-3 btn-chesswala bid-form-button'
              type='submit'
              disabled={loadingSubmitForm}
            >
              {'SEND '}
              {loadingSubmitForm && <Spinner animation='border' size='sm' />}
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default BidForm;
