import { API } from 'aws-amplify';
import { NewsletterFormValues } from '../types/formTypes';

export const joinNewsletter = async (
  data: NewsletterFormValues
): Promise<void> => {
  await API.post('chesswalaAPI', '/api/join-newsletter', {
    queryStringParameters: {
      email: data.email,
    },
  });
};
