import { API, Auth } from 'aws-amplify';
import { MyPostingCardData } from '../types/dataTypes';

export const getMyPostings = async (): Promise<MyPostingCardData[]> => {
  const response = await API.get('chesswalaAPI', '/api/get-my-postings', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
  return response;
};
