import { Container } from 'react-bootstrap';
import { MyPostingCards } from '..';
import MyInactivePostingCards from './MyInactivePostingCards';
import { useState, useEffect } from 'react';
import { getMyPostings } from '../../api/getMyPostings';
import { MyPostingCardData } from '../../types/dataTypes';

const MyPostings = () => {
  const [myActivePostingCardListData, setMyActivePostingCardListData] =
    useState([] as MyPostingCardData[]);

  const [myInactivePostingCardListData, setMyInactivePostingCardListData] =
    useState([] as MyPostingCardData[]);

  const [loadingMyPostingCardListData, setLoadingMyPostingCardListData] =
    useState<boolean>(true);

  const handleGetMyPostings = async () => {
    let response = await getMyPostings();
    response = response.sort((a, b) => {
      const dateA = new Date(a.postDate).getTime();
      const dateB = new Date(b.postDate).getTime();
      return dateB - dateA;
    });
    const activePostings = response.filter((posting) => posting.active);
    const inactivePostings = response.filter((posting) => !posting.active);
    setMyActivePostingCardListData(activePostings);
    setMyInactivePostingCardListData(inactivePostings);
    setLoadingMyPostingCardListData(false);
  };
  useEffect(() => {
    handleGetMyPostings();
  }, []);

  return (
    <Container className='px-3 py-4'>
      <h1 className='pb-4 my-postings-header'>My Active Postings</h1>
      <MyPostingCards
        handleGetMyPostings={handleGetMyPostings}
        myPostingCardListData={myActivePostingCardListData}
        loadingMyPostingCardListData={loadingMyPostingCardListData}
      />
      <h1 className='pb-4 pt-5 my-postings-header'>My Inactive Postings</h1>
      <MyInactivePostingCards
        myPostingCardListData={myInactivePostingCardListData}
        loadingMyPostingCardListData={loadingMyPostingCardListData}
      />
    </Container>
  );
};
export default MyPostings;
