import { Container, Row, Button, Card, Spinner } from 'react-bootstrap';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { MyPostingCardBidProps } from '../../types/propTypes';
import { acceptBid } from '../../api/acceptBid';
import { useState, useContext } from 'react';
import StatusAlertContext from '../StatusAlert/StatusAlertContext';

const MyPostingCardBid = ({
  bidderName,
  price,
  bidderChessLink,
  bidderChessRating,
  message,
  bidderEmail,
  bidderId,
  postId,
  handleGetMyPostings,
  handleCloseBidsModal,
}: MyPostingCardBidProps) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const [loadingSubmitForm, setLoadingSubmitForm] = useState<boolean>(false);

  const { setStatusAlert } = useContext(StatusAlertContext);

  const handleSendEmail = () => {
    const subject = `New Chesswala Message from ${
      user && user.attributes && user.attributes['custom:preferred-name']
    }`;

    const mailtoLink = `mailto:${bidderEmail}?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  };

  const handleAcceptBid = async () => {
    setLoadingSubmitForm(true);
    try {
      await acceptBid(bidderId, postId);
      handleGetMyPostings();
      handleCloseBidsModal();
      setStatusAlert('Success! This bid has been accepted.');
    } catch {
      setStatusAlert('Error: Please Try Again');
    }
    setLoadingSubmitForm(false);
  };

  return (
    <>
      <Card className='my-posting-card-bid-card'>
        <Card.Body>
          <Card.Title className='mb-2 my-posting-card-bid-name'>
            {bidderName}
          </Card.Title>
          <Card.Text className='my-posting-card-bid-item'>
            {'Offer: $' + price}
          </Card.Text>
          <Card.Text className='my-posting-card-bid-item'>
            {'Rating: ' + bidderChessRating}
          </Card.Text>
          <Card.Text className='my-posting-card-bid-item'>
            Page:{' '}
            <a href={bidderChessLink} target='_blank' rel='noopener noreferrer'>
              {bidderChessLink}
            </a>
          </Card.Text>
          <Container className='px-0 my-posting-card-bid-description-container'>
            <Card.Text className='my-posting-card-bid-item'>
              {message}
            </Card.Text>
          </Container>
          <Container>
            <Row className='mx-3 mb-4 mt-5'>
              <Button
                variant='outline-dark'
                className='my-posting-card-bid-contact-button'
                onClick={handleSendEmail}
              >
                {'Contact ' + bidderName}
              </Button>
            </Row>
          </Container>
          <Container>
            <Row className='mx-3 mb-4 mt-0'>
              <Button
                variant='outline-dark'
                className='my-posting-card-bid-accept-bid-button'
                onClick={handleAcceptBid}
                disabled={loadingSubmitForm}
              >
                {'Accept Offer '}
                {loadingSubmitForm && <Spinner animation='border' size='sm' />}
              </Button>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

export default MyPostingCardBid;
