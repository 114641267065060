import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface StatusAlertContextType {
  statusAlert: string | null;
  setStatusAlert: React.Dispatch<React.SetStateAction<string | null>>;
}

const initialContextValue: StatusAlertContextType = {
  statusAlert: null,
  setStatusAlert: () => {},
};

const StatusAlertContext =
  createContext<StatusAlertContextType>(initialContextValue);

interface StatusAlertProviderProps {
  children: ReactNode;
}

export const StatusAlertProvider = ({ children }: StatusAlertProviderProps) => {
  const [statusAlert, setStatusAlert] = useState<string | null>(null);

  const timerRef = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setStatusAlert(null);
    }, 3000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [statusAlert, setStatusAlert]);

  return (
    <StatusAlertContext.Provider value={{ statusAlert, setStatusAlert }}>
      {children}
    </StatusAlertContext.Provider>
  );
};

export function useStatusAlertContext() {
  return useContext(StatusAlertContext);
}

export default StatusAlertContext;
