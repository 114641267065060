import { Container } from 'react-bootstrap';
import { CreatePostingForm } from '../../forms';

const CreatePosting = () => {
  return (
    <Container className='py-4'>
      <h1 className='pb-4 px-2 create-posting-header'>Create Posting</h1>
      <Container>
        <CreatePostingForm />
      </Container>
    </Container>
  );
};

export default CreatePosting;
