import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuthenticator } from '@aws-amplify/ui-react';

const NavBar = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  return (
    <Container fluid className='px-4 py-4'>
      <Navbar collapseOnSelect expand='md'>
        <Container fluid>
          <LinkContainer to='/'>
            <Nav.Link>
              <Navbar.Brand>
                <img
                  alt='chesswala logo'
                  src='chesswalaLogoCropped.png'
                  className='nav-bar-logo'
                />
              </Navbar.Brand>
            </Nav.Link>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse id='responsive-navbar'>
            <Nav className='mt-2 me-auto'>
              <LinkContainer className='nav-bar-link' to={'/browse'}>
                <Nav.Link className='text-chesswala'>Browse Postings</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/create-posting' className='nav-bar-link'>
                <Nav.Link className='text-chesswala'>Create Posting</Nav.Link>
              </LinkContainer>
              {user &&
                user.attributes &&
                user.attributes['custom:account-type'] === 'Learner' && (
                  <LinkContainer to='/my-postings' className='nav-bar-link'>
                    <Nav.Link className='text-chesswala'>My Postings</Nav.Link>
                  </LinkContainer>
                )}
              <LinkContainer to='/contact-us' className='nav-bar-link'>
                <Nav.Link className='text-chesswala pb-0'>Contact Us</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id='responsive-navbar'>
            <Nav className='mt-2 ms-auto'>
              {user ? (
                <LinkContainer className='nav-bar-link' to={'/'}>
                  <Nav.Link onClick={signOut} className='text-chesswala'>
                    Sign Out
                  </Nav.Link>
                </LinkContainer>
              ) : (
                <LinkContainer className='nav-bar-link' to={'/sign-in'}>
                  <Nav.Link className='text-chesswala'>Sign In</Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
};

export default NavBar;
