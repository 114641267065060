import {
  Welcome,
  CoachMeet,
  CreatePostingHome,
  RecentPostingsHome,
  JoinNewsletter,
} from '../components';

const HomePage = () => {
  return (
    <>
      <Welcome />
      <CoachMeet />
      <CreatePostingHome />
      <RecentPostingsHome />
      <JoinNewsletter />
    </>
  );
};
export default HomePage;
