import { useState } from 'react';
import {
  Container,
  Row,
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { PostingCardBid } from '..';
import { HelpTypes } from '../../types/chessLingo';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { PostingCardProps } from '../../types/propTypes';

const PostingCard = ({
  homePage,
  posterId,
  posterName,
  postId,
  gameLink,
  description,
  helpType,
  opening,
  postDate,
  promoCode,
  bidderIds,
}: PostingCardProps) => {
  const [showBidModal, setShowBidModal] = useState(false);
  const [manualDisabled, setManualDisabled] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);

  const hideBidModal = () => {
    setShowBidModal(false);
  };

  const disableBid = () => {
    setManualDisabled(true);
  };

  const isDisabled = (): boolean => {
    if (
      user &&
      user.attributes &&
      user.attributes['custom:account-type'] === 'Learner'
    ) {
      return true;
    }
    if (user && user.username) {
      return bidderIds.includes(user.username);
    }
    return false;
  };

  const disabled: boolean = isDisabled();

  const toolTipMessage =
    user &&
    user.attributes &&
    user.attributes['custom:account-type'] === 'Learner'
      ? 'Only coaches can bid on postings'
      : 'You have already bid on this posting';

  const classPrefix = homePage
    ? ' home-posting-card-'
    : ' browse-posting-card-';
  return (
    <>
      <Card className={classPrefix + 'card'}>
        <Card.Body>
          <Card.Title className={'mb-2 ' + classPrefix + 'name'}>
            {posterName}
          </Card.Title>
          <Card.Text className={'mb-2 ' + classPrefix + 'description'}>
            {helpType}
          </Card.Text>
          <Container
            className={'px-0 ' + classPrefix + 'description-container'}
          >
            <Card.Text className={classPrefix + 'description'}>
              {description}
            </Card.Text>
          </Container>
          <Container>
            <Row className='mx-3 mb-4 mt-5'>
              <Button
                variant='outline-dark'
                onClick={() => {
                  window.open(gameLink, '_blank');
                }}
                className={
                  classPrefix +
                  'view-game-button' +
                  (helpType === HelpTypes.Analysis
                    ? ''
                    : classPrefix + 'invisible')
                }
              >
                VIEW GAME
              </Button>
            </Row>
          </Container>
          {!homePage && (
            <Container>
              <Row className='mx-0'>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    disabled || manualDisabled ? (
                      <Tooltip id='tooltip-disabled'>{toolTipMessage}</Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <span>
                    <Button
                      size='lg'
                      className={
                        'rounded-pill py-3 mb-3 btn-chesswala ' +
                        classPrefix +
                        'bid-button'
                      }
                      onClick={() => {
                        setShowBidModal(true);
                      }}
                      disabled={disabled || manualDisabled}
                    >
                      BID
                    </Button>
                  </span>
                </OverlayTrigger>
              </Row>
            </Container>
          )}
        </Card.Body>
      </Card>
      <PostingCardBid
        showBidModal={showBidModal}
        hideBidModal={hideBidModal}
        disableBid={disableBid}
        posterName={posterName}
        posterId={posterId}
        postId={postId}
        description={description}
        gameLink={gameLink}
      ></PostingCardBid>
    </>
  );
};

export default PostingCard;
