import { Formik, FormikHelpers } from 'formik';
import { Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import { NewsletterFormValidation } from './NewsletterFormValidation';
import { useContext, useState } from 'react';
import { joinNewsletter } from '../../api/joinNewsletter';
import { NewsletterFormValues } from '../../types/formTypes';
import { StatusAlertContext } from '../../components';

export const newsletterFormFields = {
  email: {
    placeholder: 'Enter your email address here...',
    controlId: 'newsletterEmail',
    type: 'email',
    name: 'email',
  },
};
export const NewsletterFormInitialValues: NewsletterFormValues = {
  email: '',
};
const NewsletterForm = () => {
  const { setStatusAlert } = useContext(StatusAlertContext);

  const [loadingSubmitForm, setLoadingSubmitForm] = useState<boolean>(false);

  const handleSubmit = async (
    values: NewsletterFormValues,
    { resetForm }: FormikHelpers<NewsletterFormValues>
  ) => {
    setLoadingSubmitForm(true);
    try {
      await joinNewsletter(values);
      resetForm();
      setStatusAlert('Success! You have joined the newsletter.');
    } catch (err) {
      setStatusAlert('Error: Please Try Again');
    }
    setLoadingSubmitForm(false);
  };
  return (
    <Formik
      validationSchema={NewsletterFormValidation}
      onSubmit={handleSubmit}
      initialValues={NewsletterFormInitialValues}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId={newsletterFormFields.email.controlId}>
                  <Form.Control
                    type={newsletterFormFields.email.type}
                    name={newsletterFormFields.email.name}
                    value={values.email}
                    onChange={handleChange}
                    isValid={touched.email && !errors.email}
                    isInvalid={touched.email && !!errors.email}
                    placeholder={newsletterFormFields.email.placeholder}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Button
                  className='px-5 btn-chesswala newsletter-form-button'
                  type='submit'
                  disabled={loadingSubmitForm}
                >
                  {'Join '}
                  {loadingSubmitForm && (
                    <Spinner animation='border' size='sm' />
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default NewsletterForm;
