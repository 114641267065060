import { API, Auth } from 'aws-amplify';
import { PostingData } from '../types/dataTypes';

export const getPostings = async (): Promise<PostingData[]> => {
  const response = await API.get('chesswalaAPI', '/api/get-postings', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });
  return response;
};
