import { Container, ListGroup, Spinner } from 'react-bootstrap';
import { MyInactivePostingCard } from '..';
import { MyInactivePostingCardsProps } from '../../types/propTypes';

const MyInactivePostingCards = ({
  myPostingCardListData,
  loadingMyPostingCardListData,
}: MyInactivePostingCardsProps) => {
  const lastCardIndex = myPostingCardListData.length - 1;
  return (
    <Container className='px-0'>
      {loadingMyPostingCardListData && (
        <Spinner animation='border' className='spinner-chesswala' />
      )}
      {lastCardIndex === -1 && !loadingMyPostingCardListData ? (
        <h5 className='px-3'>You have no inactive postings</h5>
      ) : (
        <ListGroup horizontal className='overflow-auto'>
          {myPostingCardListData.map((posting, i) => (
            <ListGroup.Item
              className={
                'flex-fill border-top-0 border-start-0 border-bottom-0 ' +
                (i === lastCardIndex
                  ? 'my-inactive-posting-cards-last-card'
                  : 'my-inactive-posting-cards-card')
              }
              key={'inactive-posting-card-' + i.toString()}
            >
              <MyInactivePostingCard
                posterName={posting.posterName}
                description={posting.description}
                helpType={posting.helpType}
              ></MyInactivePostingCard>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};
export default MyInactivePostingCards;
