import { Container } from 'react-bootstrap';
import { CoachCardList } from '..';

const CoachMeet = () => {
  return (
    <Container fluid className='px-0 mb-4 pb-3'>
      <h1 className='py-3 px-2 mx-5 coach-meet-title'>
        Meet Some Chesswala Coaches
      </h1>
      <CoachCardList />
    </Container>
  );
};

export default CoachMeet;
