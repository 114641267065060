import { Button, Col, Container, Ratio, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Welcome = () => {
  return (
    <Container fluid className='ps-4 mb-5 mt-5'>
      <Row>
        <Col lg={6} className='mb-4 px-5'>
          <h1 className='mb-3 welcome-header'>Welcome to Chesswala!</h1>
          <p>
            Chesswala is a game analysis marketplace that connects curious chess
            learners to master-level chess coaches.
          </p>
          <p>
            How does it work? It's simple. Just create a posting for one of your
            online games and chess masters will bid to analyze your game. Once
            you select one of our master coaches, you will receive a
            screen-recorded video with a full analysis of your game.
          </p>
          <LinkContainer className='welcome-sign-up-button' to='/sign-up'>
            <Button size='lg' className='btn-chesswala mx-auto mt-3'>
              SIGN UP
            </Button>
          </LinkContainer>
        </Col>
        <Col className='px-5'>
          <Ratio aspectRatio='16x9'>
            <iframe
              title='ChesswalaVideo'
              src='https://www.youtube.com/embed/GyKL4O_CqB4'
            />
          </Ratio>
        </Col>
      </Row>
    </Container>
  );
};

export default Welcome;
