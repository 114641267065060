import { Container, Card } from 'react-bootstrap';
import { MyInactivePostingCardProps } from '../../types/propTypes';

const MyInactivePostingCard = ({
  posterName,
  description,
  helpType,
}: MyInactivePostingCardProps) => {
  return (
    <Card className='my-inactive-posting-card-card'>
      <Card.Body>
        <Card.Title className='mb-2 my-inactive-posting-card-name'>
          {posterName}
        </Card.Title>
        <Card.Text className='my-inactive-posting-card-description'>
          {helpType}
        </Card.Text>
        <Container className='px-0 my-inactive-posting-card-description-container'>
          <Card.Text className='my-inactive-posting-card-description'>
            {description}
          </Card.Text>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default MyInactivePostingCard;
