import { API, Auth } from 'aws-amplify';

export const bid = async (
  posterId: string,
  postId: string,
  price: number,
  message: string
): Promise<void> => {
  await API.post('chesswalaAPI', '/api/bid', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      postId: postId,
      posterId: posterId,
      price: price,
      message: message,
    },
  });
};
