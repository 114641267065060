import { Container, ListGroup } from 'react-bootstrap';
import { MyPostingCardBid } from '..';
import { MyPostingCardBidsProps } from '../../types/propTypes';

const MyPostingCardBids = ({
  bids,
  handleGetMyPostings,
  handleCloseBidsModal,
}: MyPostingCardBidsProps) => {
  const lastCardIndex = bids.length - 1;
  return (
    <Container className='px-0'>
      {lastCardIndex === -1 ? (
        <h5 className='px-3'>No one has bid on this posting</h5>
      ) : (
        <ListGroup horizontal className='overflow-auto'>
          {bids.map((bid, i) => (
            <ListGroup.Item
              className={
                'flex-fill border-top-0 border-start-0 border-bottom-0 ' +
                (i === lastCardIndex
                  ? 'my-posting-card-bids-last-card'
                  : 'my-posting-card-bids-card')
              }
              key={'posting-card-' + i.toString()}
            >
              <MyPostingCardBid
                {...bid}
                handleGetMyPostings={handleGetMyPostings}
                handleCloseBidsModal={handleCloseBidsModal}
              ></MyPostingCardBid>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};
export default MyPostingCardBids;
