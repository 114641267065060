import { Container, ListGroup, Spinner } from 'react-bootstrap';
import { MyPostingCard } from '..';
import { MyPostingCardsProps } from '../../types/propTypes';

const MyPostingCards = ({
  handleGetMyPostings,
  myPostingCardListData,
  loadingMyPostingCardListData,
}: MyPostingCardsProps) => {
  const lastCardIndex = myPostingCardListData.length - 1;

  return (
    <Container className='px-0'>
      {loadingMyPostingCardListData && (
        <Spinner animation='border' className='spinner-chesswala' />
      )}
      {lastCardIndex === -1 && !loadingMyPostingCardListData ? (
        <h5 className='px-3'>You have no postings</h5>
      ) : (
        <ListGroup horizontal className='overflow-auto'>
          {myPostingCardListData.map((posting, i) => (
            <ListGroup.Item
              className={
                'flex-fill border-top-0 border-start-0 border-bottom-0 ' +
                (i === lastCardIndex
                  ? 'my-posting-cards-last-card'
                  : 'my-posting-cards-card')
              }
              key={'posting-card-' + i.toString()}
            >
              <MyPostingCard
                handleGetMyPostings={handleGetMyPostings}
                {...posting}
              ></MyPostingCard>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};
export default MyPostingCards;
