import { Container } from 'react-bootstrap';
const NotFoundPage = () => {
  return (
    <Container>
      <h1>Oops! 404 Error</h1>
      <p>
        The page you were looking for does not exist! Please click the Chesswala
        logo above to return to the home page.
      </p>
    </Container>
  );
};
export default NotFoundPage;
