import { Modal } from 'react-bootstrap';
import { BidForm } from '../../forms';
import { PostingCardBidProps } from '../../types/propTypes';

const PostingCardBid = ({
  showBidModal,
  hideBidModal,
  disableBid,
  posterName,
  posterId,
  postId,
  description,
}: PostingCardBidProps) => {
  return (
    <Modal
      size='lg'
      show={showBidModal}
      onHide={() => {
        hideBidModal();
      }}
    >
      <Modal.Header
        closeButton
        className='border-0 pb-0 pt-3 posting-card-bid-header'
      >
        {posterName}
      </Modal.Header>
      <Modal.Body className='pt-1 pb-4 posting-card-bid-description'>
        {description}
        <BidForm
          disableBid={disableBid}
          hideBidModal={hideBidModal}
          posterId={posterId}
          postId={postId}
          posterName={posterName}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PostingCardBid;
