import * as yup from 'yup';
import { requiredFieldMessage } from '../requiredFieldMessage';

export const BidFormValidation = yup.object().shape({
  price: yup
    .number()
    .positive('Price must be positive')
    .max(999, 'Price must be less than 1000')
    .required(requiredFieldMessage),
  message: yup
    .string()
    .max(2000, 'Message must be no more than 2,000 characters')
    .required(requiredFieldMessage),
});
