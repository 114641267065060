import { Container } from 'react-bootstrap';
import { NewsletterForm } from '../../forms';

const JoinNewsletter = () => {
  return (
    <Container fluid className='px-1'>
      <Container fluid className='mx-0 px-5 mb-5 join-newsletter-container'>
        <h1 className='join-newsletter-header'>
          Join Our Newsletter for Weekly Chess Tips and Tricks to Boost Your
          Game!
        </h1>
        <NewsletterForm />
      </Container>
    </Container>
  );
};

export default JoinNewsletter;
