import { Route, Routes } from 'react-router-dom';
import {
  HomePage,
  ContactUsPage,
  NotFoundPage,
  BrowsePage,
  CreatePostingPage,
  SignInPage,
  MyPostingsPage,
  UpdatePostingPage,
} from './pages';
import { Container } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import {
  ScrollToTop,
  NavBar,
  RequireAuth,
  Footer,
  StatusAlert,
} from './components';
import { StatusAlertProvider } from './components/StatusAlert/StatusAlertContext';

const App = () => {
  return (
    <Authenticator.Provider>
      <StatusAlertProvider>
        <BrowserRouter>
          <ScrollToTop />
          <StatusAlert />
          <Container fluid className='px-0 d-flex flex-column min-vh-100'>
            <NavBar />
            <Routes>
              <Route path='*' element={<NotFoundPage />} />
              <Route path='/' element={<HomePage />} />
              <Route
                path='/sign-up'
                element={<SignInPage initialState='signUp' />}
              />
              <Route
                path='/sign-in'
                element={<SignInPage initialState='signIn' />}
              />
              <Route path='/contact-us' element={<ContactUsPage />} />
              <Route
                path='/browse'
                element={
                  <RequireAuth>
                    <BrowsePage />
                  </RequireAuth>
                }
              />
              <Route
                path='/create-posting'
                element={
                  <RequireAuth>
                    <CreatePostingPage />
                  </RequireAuth>
                }
              />
              <Route
                path='/my-postings'
                element={
                  <RequireAuth>
                    <MyPostingsPage />
                  </RequireAuth>
                }
              />
              <Route
                path='/my-postings/:post_id'
                element={
                  <RequireAuth>
                    <UpdatePostingPage />
                  </RequireAuth>
                }
              />
            </Routes>
            <Footer />
          </Container>
        </BrowserRouter>
      </StatusAlertProvider>
    </Authenticator.Provider>
  );
};

export default App;
