import { Container } from 'react-bootstrap';
import { PostingCardList } from '..';

const BrowsePostings = () => {
  return (
    <Container className='px-3 py-4'>
      <h1 className='pb-4 browse-postings-header'>Open Postings</h1>
      <PostingCardList homePage={false} />
    </Container>
  );
};
export default BrowsePostings;
