export enum chessTitles {
  NM = 'NM',
  CM = 'CM',
  FM = 'FM',
}

export enum chessSpecialties {
  OP = 'Opening Prep',
  PSC = 'Positional Strategy Creation',
  C = 'Calculation',
  MGS = 'Middlegame Strategy',
  IOP = 'Innovative Opening Play',
  EGC = 'Endgame Calculation',
  OT = 'Opening Theory',
  TV = 'Tactical Vision',
}

export const accountTypes = ['Learner', 'Coach'];

export enum HelpTypes {
  Analysis = 'Game analysis',
  Opening = 'Learn an opening',
}
