import { Container } from 'react-bootstrap';
import { UpdatePostingForm } from '../../forms';

const UpdatePosting = () => {
  return (
    <Container className='py-4'>
      <h1 className='pb-4 px-2 create-posting-header'>Update Posting</h1>
      <Container>
        <UpdatePostingForm />
      </Container>
    </Container>
  );
};

export default UpdatePosting;
