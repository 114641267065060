import { API, Auth } from 'aws-amplify';

export const acceptBid = async (
  accepted_bidder_id: string,
  postId: string
): Promise<void> => {
  await API.put('chesswalaAPI', '/api/accept-bid', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      postId: postId,
      acceptedBidderId: accepted_bidder_id,
    },
  });
};
