import { chessTitles, chessSpecialties } from './chessLingo';
export const homePageCoaches = [
  {
    img: 'coaches/dave_mohan.jpg',
    title: chessTitles.NM,
    name: 'Dave Mohan',
    peakRating: 2205,
    specialties: [chessSpecialties.OP, chessSpecialties.PSC],
  },
  {
    img: 'coaches/alec_andersen.png',
    title: chessTitles.NM,
    name: 'Alec Andersen',
    peakRating: 2234,
    specialties: [
      chessSpecialties.C,
      chessSpecialties.MGS,
      chessSpecialties.IOP,
    ],
  },
  {
    img: 'coaches/jun_wei_lee.jpg',
    title: chessTitles.FM,
    name: 'Jun Wei Lee',
    peakRating: 2238,
    specialties: [
      chessSpecialties.EGC,
      chessSpecialties.OT,
      chessSpecialties.TV,
    ],
  },
];
