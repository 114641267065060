import { Modal } from 'react-bootstrap';
import { MyPostingCardBids } from '..';
import { MyPostingCardBidsModalProps } from '../../types/propTypes';

const MyPostingCardBidsModal = ({
  showModal,
  hideModal,
  bids,
  handleGetMyPostings,
}: MyPostingCardBidsModalProps) => {
  return (
    <Modal
      size='lg'
      show={showModal}
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header
        closeButton
        className='border-0 pb-0 pt-3 my-posting-card-bids-modal-header'
      >
        Bids
      </Modal.Header>
      <Modal.Body className='pt-1 pb-4 my-posting-card-bids-modal-description'>
        <MyPostingCardBids
          bids={bids}
          handleGetMyPostings={handleGetMyPostings}
          handleCloseBidsModal={hideModal}
        />
      </Modal.Body>
    </Modal>
  );
};

export default MyPostingCardBidsModal;
