import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import {
  Authenticator,
  useAuthenticator,
  View,
  ThemeProvider,
  TextField,
  SelectField,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router-dom';

export type SignInPageProps = {
  initialState: 'signIn' | 'signUp';
};

export const SignInPage = ({ initialState }: SignInPageProps) => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <ThemeProvider>
      <Container className='my-5'>
        <View className='auth-wrapper'>
          <Authenticator
            initialState={initialState}
            components={{
              SignUp: {
                FormFields() {
                  const { validationErrors } = useAuthenticator();
                  return (
                    <>
                      <Authenticator.SignUp.FormFields />
                      <TextField
                        isRequired
                        label='Preferred Name'
                        id='preferredName'
                        name='custom:preferred-name'
                        placeholder='ex: Dave Mohan'
                        type='text'
                        errorMessage={validationErrors.preferredName as string}
                        hasError={!!validationErrors.preferredName}
                      />
                      <TextField
                        isRequired
                        label='Chess Rating (Online, USCF, FIDE, etc)'
                        id='chessRating'
                        name='custom:chess-rating'
                        placeholder='ex: 2000'
                        type='number'
                        errorMessage={validationErrors.chessRating as string}
                        hasError={!!validationErrors.chessRating}
                      />
                      <TextField
                        isRequired
                        label='Link to Online Chess Account'
                        id='chessLink'
                        name='custom:chess-link'
                        placeholder='ex: https://www.chesswala.com'
                        type='url'
                        errorMessage={validationErrors.chessLink as string}
                        hasError={!!validationErrors.chessLink}
                      />
                      <SelectField
                        isRequired
                        label='Account Type'
                        id='accountType'
                        name='custom:account-type'
                        placeholder='Are you a coach or a learner?'
                        errorMessage={validationErrors.accountType as string}
                        hasError={!!validationErrors.accountType}
                      >
                        <option value='Learner'>Learner</option>
                        <option value='Coach'>Coach</option>
                      </SelectField>
                    </>
                  );
                },
              },
            }}
            services={{
              async validateCustomSignUp(formData, touchData) {
                const preferredNameError =
                  touchData['custom:preferred-name'] &&
                  !formData['custom:preferred-name'] &&
                  'Preferred name is required';
                const chessRatingError =
                  touchData['custom:chess-rating'] &&
                  !formData['custom:chess-rating'] &&
                  'Chess rating required';
                const chessLinkError =
                  touchData['custom:chess-link'] &&
                  !formData['custom:chess-link'] &&
                  'Link is required';
                const accountTypeError =
                  touchData['custom:account-type'] &&
                  !formData['custom:account-type'] &&
                  'Acount type is required';
                if (
                  preferredNameError ||
                  chessLinkError ||
                  chessRatingError ||
                  accountTypeError
                ) {
                  return {
                    preferredName: preferredNameError,
                    chessLink: chessLinkError,
                    chesssRating: chessRatingError,
                    accountType: accountTypeError,
                  };
                }
              },
            }}
          ></Authenticator>
        </View>
      </Container>
    </ThemeProvider>
  );
};

export default SignInPage;
