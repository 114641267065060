import { API, Auth } from 'aws-amplify';
import { UpdatePostingFormValues } from '../types/formTypes';

export const updatePosting = async (
  data: UpdatePostingFormValues,
  postId: string
): Promise<void> => {
  await API.put('chesswalaAPI', '/api/update-posting', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      postId: postId,
      helpType: data.helpType,
      gameLink: data.gameLink,
      opening: data.opening,
      description: data.description,
      promoCode: data.promoCode,
    },
  });
};
