import { Container } from 'react-bootstrap';
import { ContactUsForm } from '../../forms';

const ContactUs = () => {
  return (
    <Container className='p-5 m-0 contact-us-container'>
      <h2 className='mb-3 contact-us-header'>Send Us An Email!</h2>
      <ContactUsForm />
    </Container>
  );
};
export default ContactUs;
