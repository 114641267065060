import { Container, ListGroup, Spinner } from 'react-bootstrap';
import { PostingCard } from '..';
import { useEffect, useState } from 'react';
import { getPostings } from '../../api/getPostings';
import { getPostingsHomePage } from '../../api/getPostingsHomePage';
import { PostingCardListProps } from '../../types/propTypes';
import { PostingData } from '../../types/dataTypes';

const PostingCardList = ({ homePage }: PostingCardListProps) => {
  const [postingCardListData, setPostingCardListData] = useState<PostingData[]>(
    []
  );
  const [loadingPostingCardListData, setLoadingPostingCardListData] =
    useState<boolean>(true);

  const handleGetPostings = async () => {
    const setPostings = (response: PostingData[]) => {
      setPostingCardListData(
        response.sort((a, b) => {
          const dateA = new Date(a.postDate).getTime();
          const dateB = new Date(b.postDate).getTime();
          return dateB - dateA;
        })
      );
    };

    if (homePage) {
      setPostings(await getPostingsHomePage());
    } else {
      setPostings(await getPostings());
    }
    setLoadingPostingCardListData(false);
  };

  useEffect(() => {
    handleGetPostings();
    // eslint-disable-next-line
  }, []);

  const lastCardIndex = postingCardListData.length - 1;

  return (
    <Container className='px-0'>
      {loadingPostingCardListData && (
        <Spinner animation='border' className='spinner-chesswala' />
      )}
      {lastCardIndex === -1 && !loadingPostingCardListData ? (
        <h5 className='px-5'>No Recent Postings</h5>
      ) : (
        <ListGroup horizontal className='overflow-auto'>
          {postingCardListData.map((posting, i) => (
            <ListGroup.Item
              className={
                'flex-fill border-top-0 border-start-0 border-bottom-0 ' +
                (i === lastCardIndex
                  ? 'posting-card-list-last-card'
                  : 'posting-card-list-card')
              }
              key={'posting-card-' + i.toString()}
            >
              <PostingCard {...posting} homePage={homePage}></PostingCard>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};
export default PostingCardList;
