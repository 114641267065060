import { Container } from 'react-bootstrap';
import { PostingCardList } from '..';

const RecentPostingsHome = () => {
  return (
    <Container fluid className='px-2 mb-5'>
      <h1 className='my-5 mx-5 recent-postings-home-header'>Recent Postings</h1>
      <PostingCardList homePage></PostingCardList>
    </Container>
  );
};

export default RecentPostingsHome;
