import { Formik } from 'formik';
import { Button, Form, Spinner } from 'react-bootstrap';
import { CreatePostingFormValidation } from './CreatePostingFormValidation';
import { useContext, useState } from 'react';
import { createPosting } from '../../api/createPosting';
import { HelpTypes } from '../../types/chessLingo';
import { useNavigate } from 'react-router-dom';
import { CreatePostingFormValues } from '../../types/formTypes';
import { StatusAlertContext } from '../../components';

export const createPostingFormFields = {
  helpType: {
    label: 'What can we help you with?',
    placeholder: 'Choose an option...',
    name: 'helpType',
    controlId: 'createPostingHelpType',
  },
  gameLink: {
    label: 'Enter online game link',
    placeholder: 'i.e. www.chesswala.com',
    type: 'text',
    name: 'gameLink',
    controlId: 'createPostingGameLink',
  },
  description: {
    label: 'What do you want to get out of your lesson?',
    placeholder:
      'i.e. I want to gain a better understanding of how to play the Sicilian. I want to add a little spice to my repertoire!',
    type: 'text',
    name: 'description',
    controlId: 'createPostingDescription',
  },
  opening: {
    label: 'Enter desired opening',
    placeholder: 'i.e. Sicilian Defense for Black',
    type: 'text',
    name: 'opening',
    controlId: 'createPostingOpening',
  },
  promoCode: {
    label: 'Streamer Code / Promo Code (Optional)',
    placeholder: 'Type here...',
    type: 'text',
    name: 'promoCode',
    controlId: 'createPostingPromoCode',
  },
};

export const CreatePostingFormInitialValues: CreatePostingFormValues = {
  helpType: HelpTypes.Analysis,
  description: '',
  opening: '',
  gameLink: '',
  promoCode: '',
};

const CreatePostingForm = () => {
  const { setStatusAlert } = useContext(StatusAlertContext);

  const [loadingSubmitForm, setLoadingSubmitForm] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async (values: CreatePostingFormValues) => {
    setLoadingSubmitForm(true);
    try {
      await createPosting(values);
      navigate('/my-postings');
      setStatusAlert('Success! Your posting has been created.');
    } catch {
      setStatusAlert('Error: Please Try Again');
      window.scrollTo(0, 0);
    }
    setLoadingSubmitForm(false);
  };

  return (
    <Formik
      validationSchema={CreatePostingFormValidation}
      onSubmit={handleSubmit}
      initialValues={CreatePostingFormInitialValues}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group
              className='mb-3 mt-4'
              controlId={createPostingFormFields.helpType.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {createPostingFormFields.helpType.label}
              </Form.Label>
              <Form.Select
                name={createPostingFormFields.helpType.name}
                value={values.helpType}
                onChange={handleChange}
                isValid={touched.helpType && !errors.helpType}
                isInvalid={touched.helpType && !!errors.helpType}
                placeholder={createPostingFormFields.helpType.placeholder}
              >
                <option>{HelpTypes.Analysis}</option>
                <option>{HelpTypes.Opening}</option>
              </Form.Select>
              <Form.Control.Feedback type='invalid'>
                {errors.helpType}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={
                values.helpType === HelpTypes.Analysis
                  ? 'mb-3'
                  : 'create-posting-form-invisible'
              }
              controlId={createPostingFormFields.gameLink.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {createPostingFormFields.gameLink.label}
              </Form.Label>
              <Form.Control
                type={createPostingFormFields.gameLink.type}
                name={createPostingFormFields.gameLink.name}
                value={values.gameLink}
                onChange={handleChange}
                isValid={touched.gameLink && !errors.gameLink}
                isInvalid={touched.gameLink && !!errors.gameLink}
                placeholder={createPostingFormFields.gameLink.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.gameLink}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={
                values.helpType === HelpTypes.Opening
                  ? 'mb-3'
                  : 'create-posting-form-invisible'
              }
              controlId={createPostingFormFields.opening.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {createPostingFormFields.opening.label}
              </Form.Label>
              <Form.Control
                type={createPostingFormFields.opening.type}
                name={createPostingFormFields.opening.name}
                value={values.opening}
                onChange={handleChange}
                isValid={touched.opening && !errors.opening}
                isInvalid={touched.opening && !!errors.opening}
                placeholder={createPostingFormFields.opening.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.opening}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId={createPostingFormFields.description.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {createPostingFormFields.description.label}
              </Form.Label>
              <Form.Control
                type={createPostingFormFields.description.type}
                name={createPostingFormFields.description.name}
                value={values.description}
                onChange={handleChange}
                isValid={touched.description && !errors.description}
                isInvalid={touched.description && !!errors.description}
                placeholder={createPostingFormFields.description.placeholder}
                as='textarea'
                rows={4}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className='mb-3'
              controlId={createPostingFormFields.promoCode.controlId}
            >
              <Form.Label className='create-posting-form-label'>
                {createPostingFormFields.promoCode.label}
              </Form.Label>
              <Form.Control
                type={createPostingFormFields.promoCode.type}
                name={createPostingFormFields.promoCode.name}
                value={values.promoCode}
                onChange={handleChange}
                isValid={touched.promoCode && !errors.promoCode}
                isInvalid={touched.promoCode && !!errors.promoCode}
                placeholder={createPostingFormFields.promoCode.placeholder}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.promoCode}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className='mb-3 btn-chesswala create-posting-form-button'
              type='submit'
              disabled={loadingSubmitForm}
            >
              {'Publish My Posting '}
              {loadingSubmitForm && <Spinner animation='border' size='sm' />}
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CreatePostingForm;
