import { API, Auth } from 'aws-amplify';

export const deletePosting = async (
  postId: string,
  bidderIds: string[]
): Promise<void> => {
  await API.post('chesswalaAPI', '/api/delete-posting', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      postId: postId,
      bidderIds: bidderIds,
    },
  });
};
