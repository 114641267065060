import { useContext } from 'react';
import { Toast } from 'react-bootstrap';
import StatusAlertContext from './StatusAlertContext';

const StatusAlert = () => {
  const { statusAlert } = useContext(StatusAlertContext);

  if (!statusAlert) {
    return null;
  }

  return (
    <Toast autohide className='alert-chesswala'>
      <Toast.Body className='mx-auto'>{statusAlert}</Toast.Body>
    </Toast>
  );
};
export default StatusAlert;
