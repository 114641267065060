import * as Yup from 'yup';
import { requiredFieldMessage } from '../requiredFieldMessage';
import { HelpTypes } from '../../types/chessLingo';

export const UpdatePostingFormValidation = Yup.object().shape({
  helpType: Yup.string()
    .oneOf(Object.values(HelpTypes))
    .required(requiredFieldMessage),
  gameLink: Yup.string()
    .max(2000, 'Game link must be no more than 2,000 characters')
    .when('helpType', {
      is: HelpTypes.Analysis,
      then: Yup.string().required(requiredFieldMessage),
    }),
  opening: Yup.string()
    .max(2000, 'Opening must be no more than 2,000 characters')
    .when('helpType', {
      is: HelpTypes.Opening,
      then: Yup.string().required(requiredFieldMessage),
    }),
  description: Yup.string()
    .max(2000, 'Description must be no more than 2,000 characters')
    .required(requiredFieldMessage),
  promoCode: Yup.string().max(
    1000,
    'Promo Code / Link must be no more than 1,000 characters'
  ),
});
