import { API, Auth } from 'aws-amplify';
import { CreatePostingFormValues } from '../types/formTypes';

export const createPosting = async (
  data: CreatePostingFormValues
): Promise<void> => {
  await API.post('chesswalaAPI', '/api/create-posting', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      helpType: data.helpType,
      gameLink: data.gameLink,
      opening: data.opening,
      description: data.description,
      promoCode: data.promoCode,
    },
  });
};
