import { Col, Row } from 'react-bootstrap';
import { homePageCoaches } from '../../types/homePageCoaches';
import { CoachCard } from '..';

const CoachCardList = () => {
  return (
    <Row className='mx-5'>
      {homePageCoaches.map((details, i) => (
        <Col md={4} className='py-3' key={'coach-col-' + i}>
          <CoachCard {...details}></CoachCard>
        </Col>
      ))}
    </Row>
  );
};
export default CoachCardList;
