import { API } from 'aws-amplify';
import { ContactUsFormValues } from '../types/formTypes';

export const contactUs = async (data: ContactUsFormValues): Promise<void> => {
  await API.get('chesswalaAPI', '/api/contact-us', {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
    queryStringParameters: {
      message: data.message,
    },
  });
};
