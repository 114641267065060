import { useContext, useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { MyPostingCardProps } from '../../types/propTypes';
import { MyPostingCardBidsModal, StatusAlertContext } from '..';
import { useNavigate } from 'react-router-dom';
import { deletePosting } from '../../api/deletePosting';

const MyPostingCard = ({
  posterId,
  posterName,
  postId,
  gameLink,
  description,
  helpType,
  opening,
  postDate,
  promoCode,
  bidderIds,
  bids,
  handleGetMyPostings,
}: MyPostingCardProps) => {
  const { setStatusAlert } = useContext(StatusAlertContext);

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const hideModal = () => {
    setShowModal(false);
  };

  const handleEditPosting = () => {
    navigate(`/my-postings/${postId}`, {
      state: { postId, helpType, description, opening, gameLink, promoCode },
    });
  };

  const handleDeletePosting = async () => {
    try {
      await deletePosting(postId, bidderIds);
      await handleGetMyPostings();
      setStatusAlert('Success! Your posting has been deleted.');
    } catch {
      setStatusAlert('Error: Please Try Again');
    }
  };

  return (
    <>
      <Card className='my-posting-card-card'>
        <Card.Body>
          <Card.Title className='mb-2 my-posting-card-name'>
            {posterName}
          </Card.Title>
          <Card.Text className='my-posting-card-description'>
            {helpType}
          </Card.Text>
          <Container className='px-0 my-posting-card-description-container'>
            <Card.Text className='my-posting-card-description'>
              {description}
            </Card.Text>
          </Container>
          <Container>
            <Row className='mx-3 mb-4 mt-5'>
              <Button
                variant='outline-dark'
                onClick={() => {
                  setShowModal(true);
                }}
                className='my-posting-card-see-bids-button'
              >
                SEE BIDS
              </Button>
            </Row>
          </Container>
          <Row className='text-center mx-4'>
            <Col className='mx-1 px-0'>
              <Button
                variant='outline-dark'
                className='my-posting-card-edit-button mx-0'
                onClick={handleEditPosting}
              >
                EDIT
              </Button>
            </Col>
            <Col className='mx-1 px-0'>
              <Button
                variant='outline-dark'
                className='my-posting-card-delete-button mx-0'
                onClick={handleDeletePosting}
              >
                DELETE
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <MyPostingCardBidsModal
          showModal={showModal}
          hideModal={hideModal}
          bids={bids}
          handleGetMyPostings={handleGetMyPostings}
        />
      </Card>
    </>
  );
};

export default MyPostingCard;
